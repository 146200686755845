<template>
  <div class="photo-wrapper">
    <button class="photo background-image" :style="{ backgroundImage: `url(${imgSrc})` }" @click="toggleShowPhotoView">
      <span data-icon="zoom_in">
        <vue-material-icon name="zoom_in" :size="30" />
      </span>
    </button>
    <button-basic v-if="isShowDeleteButton" color="dark" circle="13" @click="$emit('delete')">
      <close-icon />
    </button-basic>
    <transition name="bounce">
      <photo-view v-if="isShowPhotoView" :imgSrc="imgSrc" @close="toggleShowPhotoView" />
    </transition>
  </div>
</template>
<script>
import PhotoView from '@/component/photoView/PhotoView';
export default {
  name: 'Photo',
  props: ['imgSrc', 'isShowDeleteButton'],
  data() {
    return {
      isShowPhotoView: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    toggleShowPhotoView() {
      this.isShowPhotoView = !this.isShowPhotoView;
    },
  },
  components: { PhotoView },
};
</script>
<style scoped lang="scss">
@import 'Photo';
</style>
