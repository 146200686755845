var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "photo-view-wrapper" },
    [
      _c("img", { attrs: { src: _vm.imgSrc, alt: "photo" } }),
      _c(
        "button-basic",
        {
          attrs: { color: "primary", circle: "26" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_c("close-icon")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }