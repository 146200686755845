var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "photo-wrapper" },
    [
      _c(
        "button",
        {
          staticClass: "photo background-image",
          style: { backgroundImage: "url(" + _vm.imgSrc + ")" },
          on: { click: _vm.toggleShowPhotoView },
        },
        [
          _c(
            "span",
            { attrs: { "data-icon": "zoom_in" } },
            [_c("vue-material-icon", { attrs: { name: "zoom_in", size: 30 } })],
            1
          ),
        ]
      ),
      _vm.isShowDeleteButton
        ? _c(
            "button-basic",
            {
              attrs: { color: "dark", circle: "13" },
              on: {
                click: function ($event) {
                  return _vm.$emit("delete")
                },
              },
            },
            [_c("close-icon")],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "bounce" } },
        [
          _vm.isShowPhotoView
            ? _c("photo-view", {
                attrs: { imgSrc: _vm.imgSrc },
                on: { close: _vm.toggleShowPhotoView },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }