<template>
  <div class="photo-view-wrapper">
    <img :src="imgSrc" alt="photo" />
    <button-basic color="primary" circle="26" @click="$emit('close')">
      <close-icon />
    </button-basic>
  </div>
</template>
<script>
export default {
  name: 'PhotoView',
  props: ['imgSrc'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'PhotoView';
</style>
